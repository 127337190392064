import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import InsideHeader from '../components/insideheader'
import ReactMarkdown from 'react-markdown'
import Metatags from '../components/Metatags'

export default ({ data }) => {
  const page = data.dataJson
  console.log(page.meta.metatitle)
  return (
      <Layout>

      <Metatags
               title={page.meta.metatitle}
               keywords={page.meta.metakeywords}
               description={page.meta.metadescription}
               thumbnail={page.meta.metaimage}
               url={page.meta.metaurl}
               pathname={page.meta.metapathname}
           />
          <InsideHeader pageTitle={page.title} pageSubTitle={page.subTitle} pageBackground={page.titleBackground}/>
          <main className="main-content">
              <section className="section">
                  <div className="container">
                      <div className="row">
                          <div className="col-md-10 mx-auto">
                            {page.contents.map((item, index) => (
                                <>
                                <ReactMarkdown source={item.heading} escapeHtml={false} />
                                <ReactMarkdown source={item.content} escapeHtml={false} />
                                </>
                            ))}
                              <br/>
                          </div>
                      </div>
                  </div>
              </section>
          </main>
      </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    dataJson(fields: { slug: { eq: $slug } }) {
        title
        subTitle
        titleBackground
        contents {
           heading
           image
           content
           heading
         }
         meta {
             metadescription
         metakeywords
         metatitle
         metaimage
         metaurl
         metapathname
         }
    }
  }
`
